<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      jurisdiction: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      loading: true,
      applaction: [],
      applaction_count: [],
    };
  },
  mounted() {
    this.title = this.$t("menuitems.app.shop");
    this.items = [
      {
        text: this.$t("menuitems.app.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.app.shop"),
        active: true,
      },
    ];
    this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"));
    this.activeapp = JSON.parse(sessionStorage.getItem("apps"));
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getAppList();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
  },
  methods: {
    getAppList() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "applaction",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.applaction = response.data.data;
          that.applaction_count = response.data.count;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped" v-loading="loading">
              <thead>
                <tr>
                  <th class="sortStyle">{{ $t("app.text.name") }}</th>
                  <th class="sortStyle">{{ $t("app.text.note") }}</th>
                  <th class="sortStyle">{{ $t("app.text.requirement") }}</th>
                  <th class="sortStyle">{{ $t("app.text.type") }}</th>
                  <th class="sortStyle">{{ $t("app.text.price") }}</th>
                  <th class="sortStyle">{{ $t("app.text.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val,idx) in applaction" :key="idx">
                  <td>{{$t("app.list."+val.app)}}</td>
                  <td></td>
                  <td v-if="val.realname==2">{{$t("app.text.company")}}</td>
                  <td v-else>{{$t("app.text.personal")}}</td>
                  <td v-if="val.type==1">{{$t("app.text.admin")}}</td>
                  <td v-else>{{$t("app.text.cloud")}}</td>
                  <td>{{val.price}}{{$t("global.currency."+val.currency)}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>